<template>
  <v-container
      id="register"
      class="fill-height justify-center"
      tag="section"
  >
    <Overlay :overlay="globalLoading" :color="'primary'"></Overlay>
    <Messages :messages="globalMessages"></Messages>

  </v-container>
</template>

<script>
import globalMixins from "../../mixins/globalMixins";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'SocialLoginCallback',

  mixins: [globalMixins],

  components: {
    Overlay: () => import('../../components/OverlayLoading'),
    Messages: () => import('../../components/Messages'),
  },

  props: {
    strategy: {
      type: String
    }
  },

  data: () => ({
    strategies: {
      google: {
        color: 'success',
        icon: 'google-plus',
        key: 'db019d15552f7dc8d64d774967fbdfdeb3bdc0a7d61fe0af1274075fa2235c0faf71aa842b4274acbe8e84085a1702ed4a7ceb08c6a129d57882481d9ff708a6b1e44c317eb034549eb2db013522aa8c'
      },
      facebook: {
        color: 'primary',
        icon: 'facebook',
        key: '7ee522eaca139a341db9eca7c86120c1231a7b3c094b129976ccc8d1b674cf559b892ea03cc3ce72bbbb83b72e75a73b7d25950433aef6a6bd98ecedd5103d9d42d6021e633c8469d7fc00937e222092'
      },
      linkedin: {
        color: 'info',
        icon: 'linkedin',
        key: '364cdfccc75ed2df8a1e94087efeafd5119abdfe7ba9acb92fd9ea71b90f7bef7285edb9de03352373158be8d8b79ebb8c3da95ba950f2f76859790e8922f8a0e52373162c48f5cf6dc386a564f661e4'
      }
    }
  }),

  async created() {
    this.globalLoading = true;

    if (this.isAuthenticated) {
      if (!this.currentUser) {
        // if this page is requested and the user is already logged in but their info is missing, fetch the info then redirect to main dashboard
        return await this.fetchUserAndAcl().then(() => {
          return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
        });
      } else {
        // if this page is requested and the user is already logged in, redirect to main dashboard
        return this.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
      }
    }

    this.globalLoading = false;

    if (this.strategy && this.$router.currentRoute.query && this.$router.currentRoute.query.state) {
      this.endLogin3rdParty()
    }
  },

  methods: {
    ...mapActions('auth', ['setToken', 'setUserAndACL']),

    fetchUserAndAcl() {
      const self = this;
      self.globalLoading = true;
      return self.getSendData({
        url: '/user',
        method: 'get'
      }).then(response => {
        self.setUserAndACL(response);
      }).catch(error => {
        self.handleErrorResponse(error);
      }).finally(() => {
        self.globalLoading = false;
      });
    },

    endLogin3rdParty() {
      const self = this;
      this.globalLoading = true;
      let qs = {...this.$router.currentRoute.query};
      qs["return_to_page"] = window.location.origin + "/social/" + self.strategy;
      return self.executeRequest({
        url: `/oauth/${self.strategy}/callback`,
        method: 'get',
        headers: {
          env: 'portal',
          'x-key': self.strategies[self.strategy].key
        },
        params: qs
      }).then(response => {
        if(response.redirect){
          self.$router.push({path: response.redirect});
        }
        else if (response.token) {
          self.setToken(response.token);
          self.fetchUserAndAcl().then(() => {
            this.globalLoading = false;
            self.$router.push({'name': 'Dashboard', params: {envCode: 'portal'}});
          });
        } else {
          self.$router.push({'name': 'Login'});
        }
      }).catch(error => {
        self.handleErrorResponse(error);
        this.globalLoading = false;
      });
    }

  }
}
</script>